.add-view-dialog-wrapper {
    .step-contents-wrapper {
        padding-top: 50px;
    }

    // .step2-wrapper,
    // .step3-wrapper {
    //     display: flex;
    //     justify-content: flex-start;
    //     align-items: center;
    //     flex-direction: column;
    // }

    .ant-transfer-operation {
        margin: 0 35px;
    }

    .step3-wrapper {
        .transfer-wrapper {
            margin-top: 20px;
        }

        .btn-wrapper {
            .ant-select {
                margin-right: 16px;
            }
        }
    }
}