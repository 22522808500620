.mb10 {
    margin-bottom: 10px;
}

.ml5 {
    margin-left: 5px;
}

.ml10 {
    margin-left: 10px;
}

.action-item-grid-wrap .action-tool-wrap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.action-item-grid-wrap .action-tool-wrap .btn-list {
    padding-top: 20px;
}

.action-item-grid-wrap .action-tool-wrap .icon-wrap {
    padding-top: 20px;
}

.poc-list {
    padding-left: 0;
    list-style: none;
}

.detail-dialog-wrapper .tags {
    border-radius: 3px;
    padding: 5px 10px;
    color: #fff;
    line-height: 1.4285714286;
}

.detail-dialog-wrapper .tags.open {
    background: #37b400;
}

.detail-dialog-wrapper .tags.close {
    background: #ff6358;
}

.k-dialog .detail-dialog-wrapper .k-multiselect {
    width: 75%;
}

.k-dialog .detail-dialog-wrapper .k-input {
    width: 75%;
}

.k-dialog .detail-dialog-wrapper .k-form-field-wrap .k-input {
    width: 100%;
}

.table-wrap .k-grid {
    padding: 0;
}

.detail-dialog-wrapper .k-form-buttons {
    display: flex;
    justify-content: flex-end;
}
.table-wrap{
    .tag{
        width: 20px;
        height: 20px;
        border-radius: 50%;
        display: block;
        margin-bottom: 5px;
        color: #fff;
        line-height: 20px;
        text-align: center;
        &.red{
            background: #ff6358;
        }
        &.green{
            background: #37b400;
        }
    }
    .more-text{
        // width: 100px;
    // word-break: break-all;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
    }
}